.forgot-container-personal-details {
    display: flex;
    justify-content: center;
    align-items: center;
     /* background-color: ghostwhite; */
       /* min-height: 100vh; */
   min-height: auto;
   width: 99.7vw;
   margin: 0;
   padding: 0;
  }



  .radio-with-border input[type="radio"] {
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 5px;
}


  
  .forgot-card-personal-details {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    margin-bottom: 6vh;
    position: relative;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .forgot-card-applicant-agent {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    margin-bottom: 2vh;
    position: relative;
    margin-top: 10px;
    /* margin-bottom: 40px; */
  }

  .forgot-card-varification {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    margin-bottom: 3vh;
    position: relative;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  
  .input-group {
    margin-bottom: 1rem;
    display: flex;
  }
  


  .input-group-text {
    font-size: 1.5rem;
    flex: 0 0 auto;
  }
  
  .form-control {
    border-radius: 5px;
    height: 45px;
    flex: 1;
  }
  
  .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  }
  
  .forgot-password-container {
    position: relative;
    display: flex;
    align-items: end;
  }
  
  .forgot-password-link {
    color: red;
    text-decoration: none;
    cursor: pointer;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 18px;
  }
  
  .account-link-container {
    display: flex;
    justify-content: center;
  }
  
  .custom-input {
    width: 250px;
    height: 38px;
  }
  
  .image-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

  .light-#ffcc01-bg {
    background-color: rgba(255, 255, 153, 0.5); /* Very light #ffcc01 background */
    padding: 2px; /* Add padding for better appearance */
    border-radius: 8px; /* Add border radius for rounded corners */
  }

  .bg-offwhite {
    background-color:    rgb(33 37 41 / 3%); 
  }
  



  
  /* Responsive styles */

/* For tablets and smaller screens */
@media screen and (max-width: 992px) {
  .login-card {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
}

/* For smartphones */
@media screen and (max-width: 768px) {
  .login-container {
    min-height: 82vh;
  }

  .login-card {
    width: 90%;
  }

  .custom-input {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
}
