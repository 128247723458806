.practical-upload-container-contact-details {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: ghostwhite; */
    min-height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .practical-upload-card-contact-details {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    margin-bottom: 6vh;
    position: relative;
    margin-top: 20px;
  }
  
  .custom-cursor {
    cursor: pointer;
  }
  
  .thick-hr {
    height: 2px;
    background-color: #055837;
    border: none;
    margin: 0;
  }
  
  .input-group {
    margin-bottom: 1rem;
    display: flex;
  }
  
  .input-group-text {
    font-size: 1.5rem;
    flex: 0 0 auto;
  }
  
  .form-control {
    border-radius: 5px;
    height: 45px;
    flex: 1;
  }
  
  .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 18px;
  }
  
  .custom-input {
    width: 250px;
    height: 38px;
  }
  
  .image-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .light-#ffcc01-bg {
    background-color: rgba(255, 255, 153, 0.5);
    padding: 2px;
    border-radius: 8px;
  }
  
  .bg-offwhite {
    background-color: rgb(33 37 41 / 3%);
  }
  
  /* Responsive styles */
  
  /* For tablets and smaller screens */
  @media screen and (max-width: 992px) {
    .practical-upload-docs-card-contact-details {
      max-width: 90%;
      margin-bottom: 5vh;
    }
  
    .custom-input {
      width: 80%;
    }
  }
  
  /* For smartphones */
  @media screen and (max-width: 768px) {
    .practical-upload-docs-container-contact-details {
      padding: 10px;
    }
  
    .practical-upload-docs-card-contact-details {
      max-width: 100%;
      margin-top: 10px;
    }
  
    .custom-input {
      width: 100%;
    }
  
    .input-group {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .input-group-text {
      margin-bottom: 5px;
    }
  }
  