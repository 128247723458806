.forgot-container-identification {
    display: flex;
    justify-content: center;
    align-items: center;
     /* background-color: ghostwhite; */
    min-height: auto;
    /* min-height: 100vh; */
    width: 99.7vw;
    margin: 0;
    padding: 0;
  }
  
  /* CSS */
  .back-button:hover {
    text-decoration: underline;
    color: #055837;
  }
  
  .btn.btn-sm.edit:hover {
    background-color: #ffcc01 !important;
    color: #055837 !important;
  }
  
  .btn-container {
    display: flex;
    justify-content: flex-start;
  }
  
/* Pagination */
.paginate_button_current {
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  color: inherit !important;
  margin-right: 8px;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 7px;
}

.paginate_button_next,
.paginate_button_previous,
.paginate_button {
  margin-right: 8px;
  border: none;
  background: none;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 7px;
}

.paginate_button_next:not(.disabled):hover,
.paginate_button_previous:not(.disabled):hover,
.paginate_button:not(.disabled):hover {
  background-color: black;
  color: white;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 7px;
}


/* Disable hover effect for disabled buttons */
.paginate_button.disabled {
  cursor: default;
  /* background-color: grey;
  color: white; */
}

/* Disable hover effects when disabled */
.paginate_button.disabled:hover {
  /* background-color: grey;
  color: white; */
  cursor: default;
}


.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-buttons {
  display: flex;
}
  .dataTables_length select {
    width: 58px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }
  
  .dataTables_filter input[type="search"] {
    width: 200px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .dataTables_filter label i.fa {
    margin-right: 5px;
  }
  
  .thick-hr {
    height: 2px;
    background-color: #055837;
    border: none;
    margin: 0;
  }
  
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination-buttons {
    display: flex;
  }
  
  .input-group {
    margin-bottom: 1rem;
    display: flex;
  }
  
  .input-group-text {
    font-size: 1.5rem;
    flex: 0 0 auto;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 18px;
  }
  
  .account-link-container {
    display: flex;
    justify-content: center;
  }
  
  /* Responsive styles */
  
  /* For tablets and smaller screens */
  @media screen and (max-width: 992px) {
      .forgot-container-identification {
      width: 80%;
    }
    .dropdown-options {
      max-width: 400px;
      width: 90%;
    }
  }
  
  /* For smartphones */
  @media screen and (max-width: 768px) {
    .forgot-container-identification {
       min-height: auto;
    width: 103vw;
    margin: 0;
    padding: 0;
    }
  
  
    .custom-input {
      width: 80%;
    }
    .dropdown-options {
      max-width: 400px;
      width: 90%;
    }
    .btn-container {
      justify-content: space-between;
      position: sticky;
      top: 0;
      /* padding: 5px;  */
      z-index: 1;
    }
    .pagination-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .pagination-buttons {
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
    }
  
    .paginate_button,
    .paginate_button_current,
    .paginate_button_previous,
    .paginate_button_next {
      margin: 0 4px;
    }
  }
  