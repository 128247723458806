/* Stepper.css */
.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.step {
  flex: 1;
  text-align: center;
  position: relative;
  height: 50px; /* Add fixed height to the step */
}

.step-circle {
  width: 25px;
  height: 25px;
  border: 1px solid #055837;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  color: #055837;
  margin-bottom: 5px; /* Adjust margin to ensure alignment */
}

.step.active .step-circle {
  border-color: #055837;
}

.step-text {
  font-size: 13px;
  color: #055837;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-top: 5px; /* Adjust margin to ensure alignment */
}

/* Specific class for current step text */
.current-text {
  color: #ffcc01;
}

/* Horizontal line between circles */
.step:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translateY(0px);
  width: calc(100%);
  height: 4px;
  background-color: #ccc;
  z-index: -10;
}

/* Blue background for lines before current step */
.step:not(:last-child).before-current::after {
  background-color: #055837;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .stepper {
    flex-wrap: wrap;
    width: 300px; /* Maintain fixed width on small screens */
    aspect-ratio: auto; /* Allow height to adjust automatically */
  }

  .step {
    flex-basis: 100%; /* Make steps stack vertically */
    margin-bottom: 10px;
    height: auto; /* Allow height to adjust automatically */
  }

  .step-circle {
    margin-bottom: 5px; /* Adjust margin to ensure alignment */
  }

  .step-text {
    font-size: 10px;
    color: #055837;
  }

  .step:not(:last-child)::after {
    content: "";
    position: absolute;
    transform: translateY(-1px);
    width: 100%;
    height: 2px;
    background-color: #ccc;
    z-index: -1;
  }
}
