.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
   /* background-color: ghostwhite; */
  min-height: auto;
  /* min-height: 100vh; */

}

.login-card {
  width: 100%;
  max-width: 575px;
  margin: 0 auto;
  margin-top: 25px;
  /* margin-bottom: 30px; */
  margin-bottom: 7vh;
  position: relative;
  /* box-shadow: 0 0 15px 1px rgba(255, 204, 1, 0.5); */
  /* padding: 0 50px;  */
}

.login-card-input {
  padding: 0px 45px;
  /* margin-top: 10px; */
}

.input-group {
  /* margin-bottom: 1rem; */
  display: flex;
}

.input-group-text {
  font-size: 1.5rem;
  /* flex: 0 0 auto; */
}



.forgot-password-container {
  /* margin-top: 1rem; */
  display: flex;
  justify-content: flex-end;
}

.forgot-password-link {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.image-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-message {
  color: red;
  font-size: 14px;
  /* margin-bottom: 18px; */
}

.custom-input {
  width: 250px;
  /* height: 38px; */
}

/* CSS for loading animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Responsive styles */

@media screen and (max-width: 992px) {
  /* For tablets and smaller screens */
  .login-card {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  /* For smartphones */
  .login-container {
       min-height: 100vh;
  }

  .login-card {
    width: 95%;
    /* margin-bottom: 64px; */
  }

  .login-card-input {
    padding: 0 5px;
    /* margin-top: 10px; */
  }

  .custom-input {
    width: 80%;
  }
}
