.documentary-upload-docs-emp-container-contact-details {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: ghostwhite; */
  min-height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 3vh;
}

.documentary-upload-docs-emp-card-contact-details {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  /* margin-bottom: 7vh; */
  /* position: relative; */
  /* margin-top: 20px; */
}

.form-select {
  border-radius: 5px;
  height: 40px;
}

a {
  color: #055837;
  text-decoration: none;
}

a:hover {
  color: #055837;
  text-decoration: underline;
}

.square-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-group {
  margin-bottom: 1rem;
  display: flex;
}

.input-group-text {
  font-size: 1.5rem;
  flex: 0 0 auto;
}

.form-control {
  border-radius: 5px;
  height: 45px;
  flex: 1;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}

.uploadEmployment-password-container {
  position: relative;
  display: flex;
  align-items: end;
}

.uploadEmployment-password-link {
  color: red;
  text-decoration: none;
  cursor: pointer;
}

.uploadEmployment-password-link:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 18px;
}

.account-link-container {
  display: flex;
  justify-content: center;
}

.custom-input {
  width: 250px;
  height: 38px;
}

.image-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.light-highlighhhhhhhhhh-bg {
  background-color: rgb(255, 193, 7);
  padding: 2px;
  border-radius: 4.5px;
  font-size: 90%;
  font-weight: normal;
}

.bg-offwhite {
  background-color: rgb(33 37 41 / 3%);
}

.bullet-point {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
}

/* Styles for docUploaded */
.docUploaded {
  background-color: #e7f1ff;
  color: #0c63e4;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.docUploaded:hover {
  transform: scale(1.01);
}

/* Styles for docUploaded */
.docNotUploaded {
  transition: transform 0.2s ease;
  cursor: pointer;
}

.docNotUploaded:hover {
  transform: scale(1.01);
}

/* CSS */
.accordion-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header {
  margin-bottom: 0;
}

/* Responsive styles */

/* For tablets and smaller screens */
@media screen and (max-width: 992px) {
  .login-card {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
}

/* For smartphones */
@media screen and (max-width: 768px) {
  .login-container {
    min-height: 82vh;
  }

  .login-card {
    width: 90%;
  }

  .custom-input {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
}

/* For tablets and smaller screens */
@media screen and (max-width: 992px) {
  .documentary-upload-docs-emp-card-contact-details {
    width: 100%;
  }
}

/* For smartphones */
@media screen and (max-width: 768px) {
  .documentary-upload-docs-emp-card-contact-details {
    width: 100%;
  }
}
