/* Header.css */

.header-container {
    background-color: white;
    /* padding: 10px; */
  }
  
  .logo-container {
    text-align: center;
  }
  
  .header-image {
    max-width: 100%;
    height: auto;

    /* max-height: 130px; */
    max-height: 110px;

    display: block;
    margin: 0 auto;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    /* For tablets and smaller screens */
    .header-image {
      max-height: 100px;
    }
  }
  
  @media screen and (max-width: 576px) {
    /* For smartphones */
    .header-image {
      max-height: 80px;
    }
  }
  