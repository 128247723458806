.employment-table-info {
  width: 100%;
}

.back-button:hover {
  text-decoration: underline;
  color: #055837;
}

.btn.btn-sm.edit:hover {
  background-color: #ffcc01 !important;
  color: #055837 !important;
}

.btn-container {
  display: flex;
  justify-content: flex-start;
}

.dataTables_filter label i.fa {
  margin-right: 5px;
}

.thick-hr {
  height: 2px;
  background-color: #055837;
  border: none;
  margin: 0;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 18px;
}

/* Responsive styles */

/* For tablets and smaller screens */
@media screen and (max-width: 992px) {
  .submitted-info {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
}

/* For smartphones */
@media screen and (max-width: 768px) {
  .employment-table-info {
    width: 100%;
    overflow-x: auto; /* Ensure horizontal scrolling if needed */
  }

  .custom-input {
    width: 80%;
  }

  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }

  .btn-container {
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .pagination-container {
    flex-direction: column;
    align-items: stretch;
  }

  .table-responsive {
    overflow-x: auto; /* Add horizontal scrolling for small screens */
  }

  .table {
    width: 100%; /* Ensure table takes full width */
  }
}
