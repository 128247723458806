/* General hover effect */

.hover-effect:hover {
  background-color: #ffcc01 !important;
  color: #055837 !important;
}

/* Hover effect for blink-animation */
.hover-effect:hover .blink-animation {
  background-color: #055837 !important;
  color: #ffffff !important;
}

/* Hover effect for hover-number-container */
.hover-effect:hover .hover-number-container {
  background-color: #055837 !important;
  border-radius: 15px;
}

/* Hover effect for hover-number-container text */
.hover-effect:hover .hover-number-container div {
  color: #ffffff !important;
}

/* Adjusted hr style */
.thick-hr {
  height: 2px;
  background-color: #055837;
  border: none;
  margin: 0;
}

.btn-primary {
  --bs-btn-border-color: none;
  --bs-btn-hover-border-color: none;
}

/* Blink animation */
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.blink-animation {
  animation: blink 1s infinite;
}

/* CSS for extra small screens (phones, less than 576px) */
@media (max-width: 575.98px) {
  .mt-2 {
    margin-top: 10px !important;
  }
}

/* CSS for small screens (tablets, 576px - 767.98px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-2 {
    margin-top: 20px !important;
  }
}

/* CSS for medium screens (desktops, 768px - 991.98px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-2 {
    margin-top: 30px !important;
  }
}

/* CSS for large screens (large desktops, 992px and above) */
@media (min-width: 992px) {
  .mt-2 {
    margin-top: 40px !important;
  }
}
