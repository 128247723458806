.empv-container-contact-details {
  overflow-x: auto;
}

.table-header {
  background-color: #055837;
  white-space: nowrap; /* Prevent text wrapping in table headers */
}

.pending-status-button {
  background-color: #ffcc01 !important;
  border-radius: var(--bs-border-radius) !important;
  padding: 0.5rem !important;
  color: #055837 !important;
}

.verified-status-button {
  background-color: #055837 !important;
  border-radius: var(--bs-border-radius) !important;
  padding: 0.5rem !important;
  color: #ffcc01 !important;
}


.custom-modal-spe {
  max-width: 1100px;
  margin: 0 auto;
  overflow-x: auto; /* Enable horizontal scrolling for the modal */
}

/* Responsive Styles */
@media (max-width: 576px) {
  .custom-modal {
    max-width: 85%;
    height: auto;
    padding: 0 auto;
    overflow-x: scroll; /* Enable horizontal scrolling for small screens */
  }
  .text-size-control {
    font-size: 13px;
  }
  .text-size-control-extensions {
    font-size: 11px;
  }
}

/* For tablets and smaller screens */
@media screen and (max-width: 992px) {
  .login-card {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
}

/* For smartphones */
@media screen and (max-width: 768px) {
  .login-container {
    min-height: 82vh;
  }

  .login-card {
    width: 90%;
  }

  .custom-input {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
}
