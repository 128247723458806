/* Global styles */
.forgot-container-viewApplication {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: ghostwhite; */
  min-height: auto;
  width: 99.8vw;
  margin: 0;
  padding: 0;
}

.forgot-card-viewApplication {
  width: 100%;
  max-width: 85%;
  margin: 0 auto;
  margin-bottom: 6vh;
  position: relative;
  margin-top: 20px;
}


.DocHeadMain {
  width: 100%; /* Full width */
  background-color: #f0f0f0; /* Background color */
  padding: 5px 20px; /* Padding */
  border-radius: 4px; /* Rounded corners */
  display: inline-block; /* Align with content */
  font-weight: bold; /* Bold text */
  color: #055837 !important; /* Text color */
  margin: 10px 0; /* Margin */
  box-sizing: border-box; /* Include padding and border in width */
  text-align: center; /* Center text */
}


.table-container {
  width: 100%;
}

.custom-table {
  width: 100%;
  /* border-collapse: collapse; */
}

.custom-table th,
.custom-table td {
  /* border: 1px solid #dee2e6; */
  /* border: 1px solid #055837; */

  padding: 10px;
  text-align: left;
}

.bold-green {
  font-weight: bold;
  color: #055837 !important;
}

.normal-greeenn-text {
  color: #055837 !important;
}

.custom-table th:first-child,
.custom-table td:first-child {
  width: 20%;
}

.custom-table th:last-child,
.custom-table td:last-child {
  width: 80%;
}

.custom-tableData th:first-child,
.custom-tableData td:first-child {
  width: 40%;
  cursor: default;
}

.custom-tableData th:last-child,
.custom-tableData td:last-child {
  width: 60%;
  cursor: default;
}

.img-fluid {
  width: 100%;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}

.img-fluid:hover {
  transform: scale(1.04);
}

.application_button {
  cursor: pointer;
  border-bottom: none;
}

.application-button {
  border-bottom: none;
}

.application_button.disabled {
  cursor: not-allowed;

}

.application_button.active {
  /* background-color: #ffcc01 !important;
  color: #055837 !important; */

  background-color: #055837 !important;
  color: #ffcc01 !important;

}


.application_button.active_by_click {
  /* background-color: #055837 !important;
  color: #ffcc01 !important; */

  background-color: #ffcc01 !important;
  color: #055837 !important;


}



.application_button.active:hover {
  /* background-color: #055837 !important;
  color: #ffcc01 !important; */

  background-color: #ffcc01 !important;
  color: #055837 !important;


}

.application_button:not(.active):hover {
  /* background-color: #ffcc01 !important;
  color: #055837 !important; */

  background-color: #055837 !important;
  color: #ffcc01 !important;

}

.text-yellow {
  color: var(--yellow) !important;
}

.bg-green {
  background-color: #055837 !important;
}

.arrow {
  border-radius: 0px !important;
  clip-path: polygon(93% 0, 100% 50%, 93% 100%, 0% 100%, 6% 50%, 0% 0%);
}

.nav.nav-tabs {
  border-bottom: none;
}

.nav.nav-tabs .nav-item {
  border-bottom: none;
}

.nav.nav-tabs .nav-link {
  border: none;
}

.Application_buttons_container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 15px;
}

.application_button {
  flex: 1;
  margin: 0;
  padding: 10px 15px;
  text-align: center;
}

.application_button.disabled {
  opacity: 0.5;
}

.btn_green_yellow {
  background-color: #055837 !important;
  color: #ffcc01 !important;
  transition: background-color, color;
}

.btn_yellow_green {
  background-color: #ffcc01 !important;
  color: #055837 !important;
  transition: background-color, color;
}

.w-100 {
  width: 100% !important;
}

.btn_green_yellow:hover {
  background-color: #ffcc01 !important;
  color: #055837 !important;
}

.btn_yellow_green:hover {
  background-color: #055837 !important;
  color: #ffcc01 !important;
}

.dropdown-container {
  position: relative;
  overflow-y: show;
}

.options-container {
  position: sticky;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0px;
  border-radius: 5px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.toggle-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.toggle-heading {
  font-size: 20px;
  color: #055837;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

.toggle-container span {
  font-size: 16px;
}

.toggle-icons {
  margin-left: auto;
  color: #055837;
  font-size: 1.1rem;

}

.custom-table-for-submitted {
  width: 100%;
  border-collapse: collapse;
  max-height: 38px;
  overflow-y: auto;
  opacity: 10;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.options-container.show {
  max-height: 380px;
  opacity: 1;
  overflow-y: auto;
}

.custom-table-for-submitted th,
.custom-table-for-submitted td {
  /* border: 1px solid #dee2e6; */
  /* border: 1px solid #055837; */

  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.custom-table-for-submitted th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.custom-table-for-submitted a {
  color: #055837;
  text-decoration: none;
}

.custom-table-for-submitted a:hover {
  text-decoration: underline;
}

@media (max-width: 1199.98px) {
  .dropdown-column {
    margin-top: 20px;
    width: 100%;
  }

  .toggle-heading {
    font-size: 19px;
  }

  .options-container {
    position: static;
    width: 100%;
    margin-top: 5px;
  }
}

@media (max-width: 991.98px) {
  .dropdown-column {
    margin-top: 18px;
    width: 100%;
  }

  .toggle-heading {
    font-size: 18px;
  }

  .options-container {
    position: static;
    width: 100%;
    margin-top: 5px;
  }
}

@media (max-width: 767.98px) {
  .dropdown-column {
    margin-top: 15px;
    width: 100%;
  }

  .toggle-heading {
    font-size: 17px;
  }

  .options-container {
    position: static;
    width: 100%;
  }

  .forgot-container-viewApplication {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: ghostwhite; */
    min-height: auto;
    width: 103vw;
    margin: 0;
    padding: 0;
  }
  .Application_buttons_container {
    margin-bottom: 25px;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 575.98px) {
  .dropdown-column {
    margin-top: 12px;
    width: 100%;
  }

  .toggle-heading {
    font-size: 16px;
  }

  .options-container {
    position: static;
    width: 100%;
    margin-top: 5px;
  }
}

@media (max-width: 479.98px) {
  .dropdown-column {
    margin-top: 10px;
    width: 100%;
  }

  .toggle-heading {
    font-size: 15px;
  }

  .options-container {
    position: static;
    width: 100%;
    margin-top: 5px;
  }
}

@media (max-width: 374.98px) {
  .dropdown-column {
    margin-top: 8px;
    width: 100%;
  }

  .toggle-heading {
    font-size: 14px;
  }

  .options-container {
    position: static;
    width: 100%;
    margin-top: 5px;
  }

}







.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {

    border-color:  transparent;
    color: initial;
    background-color: initial;
}



.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  border-color:  transparent;

}



