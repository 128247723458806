.custom-modal {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .progress {
    background-color: #e9ecef;
  }
  
  .progress-bar {
    background-color: #055837;
    color: #ffc107;
  }
  

  
  
.thick-hr {
  height: 2px;
  background-color: #055837;
  border: none;
  margin: 0;
}


  .square-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  
    
  /* Responsive Styles */
  @media (max-width: 576px) {
    .custom-modal {
      max-width: 85%;
      height: auto;
      padding: 0 auto;
    }
    .text-size-control {
      font-size: 13px;
    }
    .text-size-control-extensions {
      font-size: 11px;
    }
  }
  