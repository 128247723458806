.custom-tableData_em th:first-child,
.custom-tableData_em td:first-child {
  width: 50%;
  cursor: default;
}

.custom-tableData_em th:last-child,
.custom-tableData_em td:last-child {
  width: 100%;
  cursor: default;
}



.pending-status-buttonnnnn {
  background-color: #ffcc01 !important;
  border-radius: var(--bs-border-radius) !important;
  padding: 0.5rem !important;
  color: #055837 !important;
  cursor: default;
}

.verified-status-buttonnnnn {
  background-color: #055837 !important;
  border-radius: var(--bs-border-radius) !important;
  padding: 0.5rem !important;
  color: #ffcc01 !important;
  cursor: default;

}
