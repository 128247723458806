.forgot-container-like-login {
    display: flex;
    justify-content: center;
    align-items: center;
     /* background-color: ghostwhite; */
    /* min-height: 72vh; */
   min-height: auto;
  }
  
  .forgot-card-like-login {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    /* margin-bottom: 3vh; */
    position: relative;
    margin-top: 15px;
    margin-bottom: 40px;
  }
  
  .input-group {
    margin-bottom: 1rem;
    display: flex;
  }
  
  .input-group-text {
    font-size: 1.5rem;
    flex: 0 0 auto;
  }
  
  .form-control {
    border-radius: 5px;
    height: 45px;
    flex: 1;
  }
  
  
  
  .forgot-card-input {
    padding: 0px 45px;
    /* margin-top: 10px; */
  }
  
  
  
  .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  }
  
  .forgot-password-container {
    position: relative;
    display: flex;
    align-items: end;
  }
  
  .forgot-password-link {
    color: red;
    text-decoration: none;
    cursor: pointer;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
    color: red;
    cursor: pointer;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 18px;
  }
  
  .account-link-container {
    display: flex;
    justify-content: center;
  }
  
  .custom-input {
    width: 250px;
    height: 38px;
  }
  
  .image-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Responsive styles */
  
  @media screen and (max-width: 992px) {
    /* For tablets and smaller screens */
    .forgot-container-like-login {
      width: 80%;
    }
  }
  
  @media screen and (max-width: 768px) {
    /* For smartphones */
    .forgot-container-like-login {
      min-height: 86vh;
      width: 100%;
    }
  
    .forgot-card-like-login {
      width: 90%;
    }
  
    .custom-input {
      width: 80%;
    }
  }
  