.documentary-container-contact-details {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: ghostwhite; */
    /* min-height: 100vh; */
    min-height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .documentary-card-contact-details {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
  }
  
  
  .thick-hr {
    height: 2px;
    background-color: #055837;
    border: none;
    margin: 0;
  }
  
  .input-group {
    margin-bottom: 1rem;
    display: flex;
  }
  
  .input-group-text {
    font-size: 1.5rem;
    flex: 0 0 auto;
  }
  
  .form-control {
    border-radius: 5px;
    height: 45px;
    flex: 1;
  }
  
  .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
  }
  

  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 18px;
  }
  

  
  .custom-input {
    width: 250px;
    height: 38px;
  }
  
  .image-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .light-#ffcc01-bg {
    background-color: rgba(
      255,
      255,
      153,
      0.5
    ); /* Very light #ffcc01 background */
    padding: 2px; /* Add padding for better appearance */
    border-radius: 8px; /* Add border radius for rounded corners */
  }
  
  .bg-offwhite {
    background-color: rgb(33 37 41 / 3%);
  }
  


  .custom-modal {
    max-width: 900px;
    margin: 0 auto;
  }
  

  
    
  /* Responsive Styles */
  @media (max-width: 576px) {
    .custom-modal {
      max-width: 85%;
      height: auto;
      padding: 0 auto;
    }
    .text-size-control {
      font-size: 13px;
    }
    .text-size-control-extensions {
      font-size: 11px;
    }
  }
  



  
  /* Responsive styles */
  
  /* For tablets and smaller screens */
  @media screen and (max-width: 992px) {
    .login-card {
      width: 80%;
    }
    .dropdown-options {
      max-width: 400px;
      width: 90%;
    }
  }
  
  /* For smartphones */
  @media screen and (max-width: 768px) {
    .login-container {
      min-height: 82vh;
    }
  
    .login-card {
      width: 90%;
    }
  
    .custom-input {
      width: 80%;
    }
    .dropdown-options {
      max-width: 400px;
      width: 90%;
    }
  }
  